import React from 'react';

import Layout from '../components/Layout';
import Gallery from '../components/Gallery';

const img_set = [
  {
    src: require('../assets/images/automotive/Car1.jpg'),
    // thumbnail: require('../assets/images/thumbs/01.jpg'),
    title: 'Automotive',
    desc: 'Car',
  },
  {
    src: require('../assets/images/containers/Blister.jpg'),
    title: 'Containers',
    desc: 'Blister Pack',
  },
  {
    src: require('../assets/images/containers/BrandyBottle1.jpg'),
    title: 'Containers',
    desc: 'Brandy Bottle',
  },
  {
    src: require('../assets/images/containers/CrownRoyal2.jpg'),
    title: 'Containers',
    desc: 'Crown Royal',
  },
  {
    src: require('../assets/images/containers/DoleJar_zpsoxhsjy0a.jpg'),
    title: 'Containers',
    desc: 'Dole Jar',
  },
  {
    src: require('../assets/images/containers/GiftBox_zpsogx0dkjz.jpg'),
    title: 'Containers',
    desc: 'Gift Box',
  },
  {
    src: require('../assets/images/containers/Propel.jpg'),
    title: 'Containers',
    desc: 'Propel',
  },
  {
    src: require('../assets/images/containers/WoodBox.jpg'),
    title: 'Containers',
    desc: 'Wood Box',
  },
  {
    src: require('../assets/images/fashion/HandBag1_zps0u8iiyba.jpg'),
    title: 'Fashion',
    desc: 'Hand Bag',
  },
  {
    src: require('../assets/images/fashion/HandBag2_zpscy4cl4fo.jpg'),
    title: 'Fashion',
    desc: 'Hand Bag',
  },
  {
    src: require('../assets/images/fashion/HandBag3_zpsl3tctazj.jpg'),
    title: 'Fashion',
    desc: 'Hand Bag',
  },
  {
    src: require('../assets/images/food/AlmondBar.jpg'),
    title: 'Food',
    desc: 'Almond Bar',
  },
  {
    src: require('../assets/images/food/BlackCherry.jpg'),
    title: 'Food',
    desc: 'Black Cherry',
  },
  {
    src: require('../assets/images/food/Cookies.jpg'),
    title: 'Food',
    desc: 'Cookies',
  },
  {
    src: require('../assets/images/food/Corn1.jpg'),
    title: 'Food',
    desc: 'Corn',
  },
  {
    src: require('../assets/images/food/CremeB.jpg'),
    title: 'Food',
    desc: 'Creme Brulee',
  },
  {
    src: require('../assets/images/food/Lime.jpg'),
    title: 'Food',
    desc: 'Lime',
  },
  {
    src: require('../assets/images/food/MilkSplash.jpg'),
    title: 'Food',
    desc: 'Milk Splash',
  },
  {
    src: require('../assets/images/food/Twizzlers.jpg'),
    title: 'Food',
    desc: 'Twizzlers',
  },
  {
    src: require('../assets/images/food/VanillaFlowers.jpg'),
    title: 'Food',
    desc: 'Vanilla Flowers',
  },
  {
    src: require('../assets/images/health-and-beauty/Bronze.jpg'),
    title: 'Health and Beauty',
    desc: 'Bronze',
  },
  {
    src: require('../assets/images/health-and-beauty/ChiBottlePromo1.jpg'),
    title: 'Health and Beauty',
    desc: 'Chi',
  },
  {
    src: require('../assets/images/health-and-beauty/Eye&CheekPalette.jpg'),
    title: 'Health and Beauty',
    desc: 'Laura Mercier',
  },
  {
    src: require('../assets/images/health-and-beauty/LipTrioRose.jpg'),
    title: 'Health and Beauty',
    desc: 'Laura Mercier',
  },
  {
    src: require('../assets/images/health-and-beauty/FarAwayBottle.jpg'),
    title: 'Health and Beauty',
    desc: 'Far Away',
  },
  {
    src: require('../assets/images/health-and-beauty/AngelsOnlyEDP.jpg'),
    title: 'Health and Beauty',
    desc: `Victoria's Secret`,
  },
  {
    src: require('../assets/images/health-and-beauty/EDP24.jpg'),
    title: 'Health and Beauty',
    desc: `Victoria's Secret`,
  },
  {
    src: require('../assets/images/health-and-beauty/HandCreamTube.jpg'),
    title: 'Health and Beauty',
    desc: `Victoria's Secret`,
  },
  {
    src: require('../assets/images/health-and-beauty/Perfume23.jpg'),
    title: 'Health and Beauty',
    desc: `Victoria's Secret`,
  },
  {
    src: require('../assets/images/health-and-beauty/VSMist.jpg'),
    title: 'Health and Beauty',
    desc: `Victoria's Secret`,
  },
  {
    src: require('../assets/images/health-and-beauty/TubesBottles1.jpg'),
    title: 'Health and Beauty',
    desc: 'Clairol',
  },
  {
    src: require('../assets/images/household/3Ply.jpg'),
    title: 'Household',
    desc: '3 Ply',
  },
  {
    src: require('../assets/images/household/GladTrashBags.jpg'),
    title: 'Household',
    desc: 'Glad',
  },
  {
    src: require('../assets/images/label-art/AxionLabelArt.jpg'),
    title: 'Label Art',
    desc: 'Axion',
  },
  {
    src: require('../assets/images/label-art/PL_TeahouseHibscus.jpg'),
    title: 'Label Art',
    desc: 'Pure Leaf',
  },
  {
    src: require('../assets/images/label-art/PureLeaf_LeavesPromo.jpg'),
    title: 'Label Art',
    desc: 'Pure Leaf',
  },
  {
    src: require('../assets/images/label-art/PureLeafHerbalIce.jpg'),
    title: 'Label Art',
    desc: 'Pure Leaf',
  },
  {
    src: require('../assets/images/lettering/Max3.jpg'),
    title: 'Lettering',
    desc: 'Pepsi',
  },
  {
    src: require('../assets/images/personal-medical/Alka1.jpg'),
    title: 'Personal/Medical',
    desc: 'Alkaseltzer',
  },
  {
    src: require('../assets/images/personal-medical/BandagePadHand.jpg'),
    title: 'Personal/Medical',
    desc: 'Bandage',
  },
  {
    src: require('../assets/images/personal-medical/Bandages1.jpg'),
    title: 'Personal/Medical',
    desc: 'Bandage',
  },
  {
    src: require('../assets/images/personal-medical/HuggiesSample.jpg'),
    title: 'Personal/Medical',
    desc: 'Huggies',
  },
  {
    src: require('../assets/images/personal-medical/KneeAnkleMed.jpg'),
    title: 'Personal/Medical',
    desc: 'Knee Ankle Med',
  },
  {
    src: require('../assets/images/personal-medical/PillEfervescence.jpg'),
    title: 'Personal/Medical',
    desc: 'Polident',
  },
  {
    src: require('../assets/images/personal-medical/Razor1.jpg'),
    title: 'Personal/Medical',
    desc: 'Razor',
  },
  {
    src: require('../assets/images/personal-medical/Razor2.jpg'),
    title: 'Personal/Medical',
    desc: 'Razor',
  },
  {
    src: require('../assets/images/personal-medical/softPads.jpg'),
    title: 'Personal/Medical',
    desc: 'Soft Pads',
  },
  {
    src: require('../assets/images/personal-medical/ToothBrushwPaste.jpg'),
    title: 'Personal/Medical',
    desc: 'Tooth Brush w/ Paste',
  },
  {
    src: require('../assets/images/photo-retouching/KatieRetouch.jpg'),
    title: 'Photo Retouch',
    desc: 'Doggo',
  },
  {
    src: require('../assets/images/photo-retouching/SplenaRetouch2.jpg'),
    title: 'Photo Retouch',
    desc: 'Splenda',
  },
  {
    src: require('../assets/images/photo-retouching/SplendaRetouch1.jpg'),
    title: 'Photo Retouch',
    desc: 'Splenda',
  },
  {
    src: require('../assets/images/razor2vector/FFlintstone.jpg'),
    title: 'Raster To Vector',
    desc: 'Fred Flinstone',
  },
  {
    src: require('../assets/images/razor2vector/PumpkinPals.jpg'),
    title: 'Raster To Vector',
    desc: 'Pumpkin Pals',
  },
  {
    src: require('../assets/images/technical/TechBoot.jpg'),
    title: 'Technical',
    desc: 'Tech Boot',
  },
  {
    src: require('../assets/images/technical/TechSnowmobile.jpg'),
    title: 'Technical',
    desc: 'Tech Snowmobile',
  },
  {
    src: require('../assets/images/technical/Peace4b.jpg'),
    title: 'Technical',
    desc: 'Peace',
  },
];
const IndexPage = () => (
  <Layout>
    <Gallery images={img_set} />
  </Layout>
);

export default IndexPage;
